'use client'
import { useEffect } from 'react'

import { Section } from '@/components'

const FormEmbed = ({
  title,
  description,
  id = 'site-institucional-c512928176385ea91934'
}: {
  title?: string
  description?: string
  id?: string
}) => {
  useEffect(() => {
    const initForm = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new window.RDStationForms(id, 'UA-195637752-1').createForm()
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.RDStationForms) {
      initForm()
    } else {
      const script = document.createElement('script')
      script.src =
        'https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js'
      script.onload = initForm
      document.body.appendChild(script)
    }
  }, [])
  return (
    <Section id="contato" className="bg-teal-600 pb-10 pt-10">
      {title && <h2 className="px-6 py-6 text-center text-3xl uppercase text-white">{title}</h2>}
      {description && (
        <p className="mx-auto mb-6 w-2/3 text-center text-xl text-white">{description}</p>
      )}
      <div className="mx-auto lg:w-1/2" role="main" id={id} />
    </Section>
  )
}

export default FormEmbed
